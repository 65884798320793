<template>
    <div class="mall-goods-type">
      <!-- 查詢 -->
      <section class="table-search">
        <div class="search-L">
        </div>
        <div class="search-R">
          <el-button type="primary" @click="onAdd()">添加兑换商品类别</el-button>
        </div>
      </section>
      <!-- 表格 -->
      <el-table :data="tableData" border :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }" row-key="goodsTypeId">
        <!-- <el-table-column label="排列序号" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sortWeight || "-" }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="分类名称" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.goodsTypeName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.addDt || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="已挂载商品数" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.goodsCount}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 编辑弹框 -->
      <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
        <!-- 表单提交 -->
        <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
          <section class="form-main">
            <el-form-item label="分类名称" prop="goodsTypeName">
              <el-input v-model.trim="params.goodsTypeName" autocomplete="off" maxlength="10" placeholder="请填写分类名称"
                clearable></el-input>
            </el-form-item>
          </section>
          <section class="form-footer">
            <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
          </section>
        </el-form>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import Sortable from 'sortablejs'
  import {
    getGoodsTypeList,
    getGoodsTypeAdd,
    getGoodsTypeEdit,
    getGoodsTypeDel,
    sortGoodsType
  } from "@/api/point/goods";
  import { rules } from "@/db/rules";
  export default {
    data() {
      return {
        // table表格 
        tableData: [],//表格数据
        // 弹框
        editShow: false, //弹框开关
        editTitle: "",//弹框标题
        // from表单
        rules,
        params: {
          goodsTypeName: "",
          sortWeight: "",
        },
      }
    },
    created() {
     
    },
    mounted() {
      this.getGoodsTypeList();// 【请求】表格数据
      this.initSort()
    },
    methods: {
      initSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      // const sortable = new Sortable(el, options);
      // 根据具体需求配置options配置项
      const sortable = new Sortable(el, {
        // number 定义鼠标选中列表单元可以开始拖动的延迟时间；
        delay: 0,
        onEnd: (evt) => { // 监听拖动结束事件
          // 我们有了 evt.oldIndex 和 evt.newIndex 这两个参数做索引，我们可以根据绑定在表格上面的 data 这个 Array 找到两个相应的记录。就可以针对数据进行操作啦。
          // 下面将拖拽后的顺序进行修改
          const currRow = this.tableData.splice(evt.oldIndex, 1)[0]
          this.tableData.splice(evt.newIndex, 0, currRow)
          let arr=[]
          this.tableData.forEach(item=>{
            arr.push(item.goodsTypeId)
          })
          this.sortGoodsType(arr)
        }
      })
    },
      // 分类排序
      sortGoodsType(arr){
        sortGoodsType({goodsTypeIds:arr}).then(res=>{
        
      })
    },
      // 【请求】点单商品分类列表
      getGoodsTypeList() {
        getGoodsTypeList().then((res) => {
          if (res.isSuccess == "yes") {
            this.tableData = res.data;
          }
        });
      },
  
      // 【请求】新增商品分类
      getGoodsTypeAdd() {
        let data = this.params;
        getGoodsTypeAdd(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.editShow = false;
            this.getGoodsTypeList();// 【请求】表格数据
          }
        });
      },
  
      // 【请求】编辑
      getGoodsTypeEdit() {
        let data = this.params;
        getGoodsTypeEdit(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.editShow = false;
            this.getGoodsTypeList();// 【请求】表格数据
          }
        });
      },
  
  
      // 【监听】新增
      onAdd() {
        this.editShow = true;
        this.editTitle = "添加兑换商品类别";
        this.params = {
          goodsTypeName: "",
          sortWeight: "",
        }
        this.$nextTick(() => {
          this.$refs["form-ref"].clearValidate();
        });
      },
  
      // 【监听】编辑
      onEdit(row) {
        this.editShow = true;
        this.editTitle = "编辑兑换商品类别";
        this.params = JSON.parse(JSON.stringify(row));
        this.$nextTick(() => {
          this.$refs["form-ref"].clearValidate();
        });
      },
  
      // 【监听】删除
      onDel(row) {
        let data = row;
        this.$confirm(row.goodsCount>0?`该类别已挂载${row.goodsCount}个兑换商品，是否确定删除？`:'是否确定删除？', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          getGoodsTypeDel(data).then(res => {
            if (res.isSuccess == "yes") {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.getGoodsTypeList();
            }
          })
        });
      },
  
  
      // 【监听】表单提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.params.goodsTypeId) {
              this.getGoodsTypeEdit();
            } else {
              this.getGoodsTypeAdd();
            }
          } else {
            return false;
          }
        });
      },
  
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .mall-goods-type {
    padding: .15rem;
  }
  
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
  }
  
  .search-L {
    display: flex;
  }
  
  .form-footer {
    display: flex;
    justify-content: flex-end;
  }
  </style>